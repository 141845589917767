import React from 'react'
import background from '../../assets/images/vp-justice-bg.png';;
import logo from '../../assets/images/vp-logo.svg';
import img1 from '../../assets/images/referrals/successReferrals.png';
import img2 from '../../assets/images/referrals/email.png';
import img3 from '../../assets/images/referrals/registrationStep2.png';
import img4 from '../../assets/images/referrals/errorReferrals.png';

import * as s from './s.module.css';
import cx from 'classnames';

class Referrals extends React.Component {
    render() {
        return (
            <section className={s.sectionContainer}>
                <div className={s.sectionContent}>
                    <img src={logo} />
                    <h3 className={cx(s.title, s.greenGradientText)}>Virtue Poker offers you a promotion where you can refer a friend and earn rewards.</h3>
                    {/* <p>
                        Explanation of the rewards: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                        in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                        sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p> */}
                    <div className={s.hr}/>
                    <h3 className={cx(s.title, s.greenGradientText)}>How to Refer a Friend?</h3>
                    <div className={cx(s.circle, s.noMarginTop)}>1</div>
                    <p>On the top menu, select Referrals, then click on refer a friend and you will see:</p>
                    <img className={s.img} src={img1} />
                    <div className={s.circle}>2</div>
                    <p>Copy the referral code and share with all the people you want, the more referrals, the more rewards. Also, you can click on share and
                        an email template with all the necessary information will be opened as below:
                    </p>
                    <img className={s.img} src={img2} />
                    <div className={s.circle}>3</div>
                    <p>Make sure to ask to your friend to input the referral code during the registration process:</p>
                    <img className={cx(s.img, s.marginBottom)} src={img3} />
                    <p>In case of any error in this step, let us know using support@virtue.poker, you will see an error as bellow:</p>
                    <img className={s.img} src={img4} />
                    <div className={s.circle}>4</div>
                    <p>After your referral has completed the registration, you're all set.</p>
                </div>
                <img className={s.background} src={background} />
            </section>
        )
    }
}

export default Referrals;
