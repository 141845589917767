import React from "react";
import Layout from "../layout/default";
import Seo from "../components/seo";
import Referrals from "../components/Referrals";

const ReferralsPage = ({ location, pageContext }) => (
    <Layout location={location} dynamicDefaultPaths={pageContext.dynamicDefaultPaths}>
        <Seo title="Referrals" />
        <Referrals />
    </Layout>
)

export default ReferralsPage;
